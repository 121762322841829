import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';
import { font } from '../../../css/common/variables';

export const STGTDisclaimerFooterWrapper = styled.div`
	.disclaimer-para {
		.o-container {
			@media (min-width: 768px) and (max-width: 991.98px) {
				padding: 0px 16px !important;
			}
			.disclaimer-para-gtb {
				z-index: 1000;
				position: relative;
				font-size: 16px;
				line-height: normal;
				margin-bottom: 0px;
				margin-top: 40px;
				color: #fff;
        cursor: default;
				font-family: ${font.gotham_book};

				strong {
					font-family: ${font.gotham_bold};
				}
			}
		}
	}
`;

export const GeneticTesting = styled.div`
	.footer-block--row {
		.footer-inner-block {
			.text-button-wrap {
				a {
					&:hover {
						@media ${device.laptopMedium} {
							position: revert !important;
						}
					}
				}
			}
		}
	}

	.o-section--cover-bg {
		position: relative;

		&::before {
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			height: calc(100% - 240px);
			width: 100%;
		}

	}

	.result-points {
		.result-step-component {
			max-width: 1168px;

			.counselor-point {
				padding: 4.8rem 50px 0;

				@media ${device.laptopMedium} {
					padding: 4.8rem 4.2rem 0;
				}

				ul {
					list-style: none;
					padding: 0;
          margin-bottom: 10px;
				}
			}

			.image-points-section {
        padding: 27px 0 24px;
        @media ${device.ipadLandscapemin} {
          padding: 93px 0 64px;
        }

				h2 {
					line-height: 5rem;
					padding: 0;
					width: 100%;
    			margin: 50px auto 0;
				}

				.o-header--h2 {
					@media ${device.laptopMedium} {
						margin-top: 50px;
						max-width: 236px;
					}
				}

				.o-inner {
					padding-left: 100px;
					padding-right: 100px;
				}

				@media ${device.laptopMedium} {
					padding: 27px 0 24px;
					.o-inner {
						padding-left: 0px;
						padding-right: 0px;
					}

					h2 {
						line-height: 3rem;
						font-size: 2.5rem;
    					padding: 0 30px;
						margin: auto;
						margin-top: 50px;
					}
				}
			}

			.image-points-section {

				h2 {
					@media ${device.laptopMedium} {
						padding: 0 30px;
						display: flex;
					}
				}

				.left-img {
					width: 52.5%;
					text-align: center;

					img {
						max-width: 100%;
					}

					@media ${device.ipadLandscape} {
						width: 100%;
    					margin: 0;
					}
				}

			}

			.o-internal--h2 {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: #000000;
				text-align: center;
				@media ${device.laptopMedium} {
					font-size: 2.5rem;
					line-height: 3rem;
					max-width: 320px;
				}
			}
		}

		.o-column--full {
			width: 100%;
		}

		.bg-color-component {
			background-color: #F7F7F7;
			-webkit-box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
			-moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
			position: relative;

			&::before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				width: 100%;
				height: 16px;
				left: 0;
				right: 0;

				@media ${device.laptopMedium} {
					height: 8px;
				}
			}
		}

		.listing-btm-section {
			padding: 40px 0 0 100px !important;
			display: flex;
			flex-wrap: wrap;

			.listing-btm-section__content {
				width: 47.5%;
   				padding-right: 48px;

				p {
					width: 100%;
					float: none;
					padding: 0 !important;
					font-size: 2.7rem;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						font-size: 2rem;
						padding: 12px 40px 20px 40px !important;
						display: grid;
						line-height: 2.4rem;
					}
				}

				.o-paragraph {
					font-weight: 500;
					font-family: 'Gotham';
				}

				.results-points-content {
					font-size: 2.2rem;

					@media ${device.laptopMedium} {
							font-size: 1.8rem;
							line-height: 2.2rem;
					}
				}
			}

			@media ${device.laptopMedium} {
				padding: 0px !important;

				.wizard-paragraph-spacing {
					padding-top: 12px !important;
					display: initial;
				}

				.results-points-content {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

				.listing-btm-section__content {
					p {
						font-size: 2rem;
						padding: 12px 40px 20px 40px !important;
						display: grid;
						line-height: 2.4rem;
						text-align: center;
					}
				}

				.listing-btm-section__content {
					width: 100%;
					padding-right: 0;
				}
			}
		}

		.counselor-block {
			p {
				float: right;
				width: 50%;
				margin: initial;
				align-items: flex-end;
				justify-content: end;
				padding: 20px 0 0 20px;
				color: #000000;
			}
		}

		.counselor-point {
			width: 100%;
			margin: auto;
			padding-top: 15rem;

			ul {
				padding: 0;
			}

			li {
				color: #000000;
				padding: 16px 0 16px 60px;
				position: relative;

				&::before {
					content: "";
					background-size: 95%;
					background-position: center;
					background-repeat: no-repeat;
					position: absolute;
					left: 0;
					top: 12px;
					background-color: #8000BE;
					color: #ffffff;
					font-size: 16px;
					border-radius: 50%;
					width: 40px;
					height: 40px;
					text-align: center;
				}

				@media ${device.laptopMedium} {
					padding: 6px 0 6px 42px;
					font-size: 1.8rem;
    				line-height: 2.2rem;

					&::before {
						top: 4px;
    					left: 0;
						bottom: initial;
						font-size: 2rem;
						margin: auto;
						width: 26px;
						height: 26px;
					}
				}
			}

			.o-paragraph {
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
			}
		}

		@media ${device.laptopMedium} {

			.counselor-point {
				.o-paragraph {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}
			}
			padding-left: 27px;
    	padding-right: 27px;
		}
	}

	footer {
		.row {
			padding-top: 0;
		}
	}

	.footercontainer {
		.left-footer {
			.FooterText1 {
				margin-bottom: 0;
			}
		}
		.pagewrapper {
			&:before {
				height: calc(100% + 150px);
			}
		}
	}

	&.genetic-testing {
		overflow: hidden;

		.internal-banner {
			.internal-banner__contant {
				@media ${device.desktopStart}{
					top: calc(50% - 15px);
				}
				@media ${device.desktopsignup} and ${device.midDesktopMax} {
					top: calc(50% - 15px);
				}
			}
		}

		.tab-content {
			#tabbed-content-tabpane-tab0 {
				.text-bg-box {
					@media ${device.desktopsignup} {
						padding-bottom: 50px !important;
					}
				}
				.steptogenetic-footer--block {
					&.footer-block {
						@media ${device.desktopsignup} {
							background-color: transparent;
							margin-top: -77px;
						}
					}
				}
			}
		}


		.o-container {
			max-width: 1208px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 2rem;

				@media ${device.laptopMedium} {
					max-width: 718px;
				}
		}

		.o-text--normal {
			font-size: 1.8rem;
			line-height: 1.56;
		}

		.listing-btm-section {
			padding: 60px 0 0;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;

			@media ${device.laptopMedium} {
				display: block;
				padding: 20px 0 0;
			}
		}

		.o-internal--h2 {
			font-size: 4.2rem;
			font-weight: 700;
			line-height: 5rem;
			color: #000000;
			text-align: center;
			font-family: Gotham, sans-serif;
			margin-bottom: 0;

			@media ${device.laptopMedium} {
				font-size: 2.5rem;
				line-height: 3rem;
			}
		}

    .text-bg-box {
      background-color: #8000be;
      padding: 21px 16px 74rem;

      @media ${device.ipadLandscapemin} {
        padding: 90px 100px 295px 90px;
      }

			p {
				padding: 40px 0;
				width: 78%;
				margin: auto;
				text-align: center;

				@media ${device.laptopMedium} {
					padding: 24px 0 42px;
				}

				&.o-title-text {
					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
						width: 85%;
						padding: 5px 8px 22px;
					}
				}
				&.disc-para {
					font-size: 18px;
					line-height: 1.22;
					width: 95%;
					padding: 31px 0px 55px;
					font-family: ${font.gotham_book};

					@media ${device.desktopsignup} {
						font-size: 22px;
						line-height: 1.45;
					}

					span {
						font-family: ${font.gotham};
					}
				}
			}
			h2, p {
				color: #fff;
			}
			.link-text {
				color: #fff !important;
				text-align: center;
				display: block;
				position: relative;
				text-decoration: underline;
				text-underline-offset: 3px;
			}

			.condition-text {
				font-family: 'Gotham-Book';
    		font-weight: 400;
				padding: 0 0 11px;
				width: 95%;

				@media ${device.laptopMedium} {
					font-size: 1.4rem;
					line-height: 1.21;
				}

				&:last-child {
					margin-bottom: 60px;
				}
			}

			.text-bg-box-link-on-highlighted {
				display: block;
				justify-content: center;
				align-items: center;
				font-size: 2.2rem;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

				&:hover {
					color: #00FFD9 !important;
					transition: ease 3ms;
					font-weight: 700;
					font-size: 2.01rem;
				}
				&:after {
					content: '';
					width: 20px;
					height: 20px;
					position: absolute;
					margin-left: 11px;
    			margin-top: 2px;
					display: inline-block;

					@media ${device.ipadLandscapemin} {
						margin-top: 8px;
					}
				}
			}
			 h2 {
				&.o-internal--h2 {
					font-family: ${font.gotham_bold};

					@media ${device.laptopMedium} {
						padding: 0px 28px;
					}
				}
			 }
		}

		.o-title-text {
			font-weight: 500;
			font-size: 2.7rem;
			line-height: 3.4rem;

			@media ${device.laptopMedium} {
				font-size: 2rem;
				line-height: 2.4rem;
			}
			@media ${device.desktopsignup} {
				line-height: 1.41;
			}
		}

		.step-info-box {
			margin: 96px auto 0;
			flex-wrap: wrap;
			width: 100%;

			p {
				padding-top: 9px;
			}
			a {
				color: #fff;
				padding: 5px 0 0 100px;
				position: relative;
				text-decoration: underline;
				text-underline-offset: 3px;

				&:hover {
					color: #00FFD9;
				}
			}
		}
		.info-box {
			display: flex;
			align-items: center;
			background-color: #300064;
			padding: 27px 102px;
			position: relative;

			@media ${device.laptopMedium} {
				display: block;
				padding: 24px 40px;
				margin: 48px auto 0;
			}

			p {
				text-align: left;
				color: #fff;
				padding: 0 0 0 101px;
				font-weight: 500;

					span {
					color: #00FFD9;
					display: initial;
				}
			}
			.media--image  {
				img {
					position: absolute;
					left: 100px;

					@media ${device.laptopMedium} {
						display: block;
    					margin: 0 auto;
						width: 45px;
						left: 0;
						right: 0;
						text-align: center;
						position: unset;
					}
				}
				&:nth-child(n-1) {
					position: relative;

					img {
						left: 8px;
					}
				}
			}
		}
		.o-paragraph {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 3.2rem;

			@media ${device.laptopMedium} {
				font-size: 1.8rem;
				line-height: 2.2rem;
			}
		}
		.counselor-section {
			padding: 180px 0 120px;


			.o-container {
				@media ${device.laptopMedium} {
					max-width: 718px !important;
				}

			}
			@media ${device.laptopMedium} {
				padding: 48px 0 24px;
			}
			.counselor-block {
				display: flex;
				align-items: center;

				p {
					float: right;
					width: 50%;
					margin: initial;
					align-items: flex-end;
					justify-content: end;
					padding: 20px 0 0 20px;
					color: #000000;
				}
			}

			div.counselor-point {
				width: 100%;
				margin: auto;
				padding-top: 15rem;

				@media ${device.laptopMedium} {
					width: 100%;
					padding: 0 0 20px 0 !important;
				}

				h3 {
					color: #000000;
					padding: 0 0 20px 40px;
					margin-bottom: 0;

					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.6rem;
						text-align: center;
						padding: 0 0 12px;
					}
				}

				span {
					color: #8000BE;
				}
				li {
					color: #000000;
					padding: 16px 0 16px 60px;
					position: relative;
					text-align: left;
    				list-style: none;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
						padding: 8px 0 8px 42px;
					}

					:before {
						content: "";
						background-size: 95%;
						background-position: center;
						background-repeat: no-repeat;
						position: absolute;
						left: 0;
						top: 12px;
						background-color: #8000BE;
						color: #ffffff;
						font-size: 16px;
						border-radius: 50%;
						width: 40px;
						height: 40px;
						text-align: center;
						@media ${device.laptopMedium} {
							font-size: 2rem;
							left: 0;
							top: 0;
							bottom: 0;
							margin: auto;
							width: 26px;
							height: 26px;
						}
					}
				}
			}

			p {
				&.o-paragraph {
					width: 37%;
					margin-left: 20px;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

          @media ${device.laptopMedium} {
            width: 100%;
            margin-left: 0;
            text-align: center;
            padding: 20px 28px !important;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin: 0 auto;
          }
        }
      }
    }

		.steps-to-genetic-testing-eye-specialist {
			position: relative;
			margin-bottom: 50px;

			:before {
				content: "";
				background-color: #00ffd9;
				height: 220px;
				position: absolute;
				left: 0;
				top: 110px;
				width: 100%;

				@media ${device.laptopPros} {
					height: 218px;
					top: 180px;
				}

				@media ${device.tablet} and ${device.laptopMedium} {
					height: 220px;
					top: 110px;
				}
			}

			.o-container {
				@media ${device.laptopMedium} {
					max-width: 718px;
				}
			}

			p {
				&.o-paragraph {
					@media ${device.laptopMedium} {
						text-align: center;
						padding: 9px 0 0;
						display: inline-block;
						line-height: 2.4rem;
						font-size: 1.8rem;
						margin-top: 3px;
						margin-bottom: 0;
					}

					span {
						font-size: 2.7rem;
						line-height: 3.8rem;
						margin-bottom: 15px;

						@media ${device.laptopMedium} {
							font-size: 2rem;
							line-height: 2.4rem;
							margin-bottom: 5px;
						}
					}
				}
			}
			a {
				display: flex;
				align-items: center;
				margin-top: 11px !important;

				&:first-child {
					@media ${device.laptopMedium} {
						padding: 0;
						font-size: 1.8rem;
						text-align: center;
					}
				}

				&:after {
					display: none;
				}

				.link-img {
					position: initial;
					width: auto !important;
				}
				 img {
					margin-left: 8px;
					right: 76px;
    			bottom: -9px;
					left: unset !important;
				 }
				 .media--image {
					position: relative;
				 }
			}

			.step-info-box {
				.o-paragraph {
					padding: 10px 0px 0px 101px;
					Margin-bottom: 0;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
          margin-top: 0;
          margin-top: 0;

          @media ${device.laptopMedium} {
            padding: 9px 0px 0px;
            line-height: 2.4rem;
            font-size: 1.8rem;
            margin-top: 3px;
          }
          span {
            font-size: 2rem;
            @media ${device.ipadLandscapemin} {
              font-size: 2.7rem;
            }
          }
				}

				> .media--image {
					img {
						left: 0;
						top: 15px;

						@media ${device.laptopMedium} {
							margin: 3px auto 0;
							width: 15%;
						}
					}
				}
			}
		}

		.image-points-section {
			.left-img {
				width: 48.4%;
				text-align: center;

				img {
					max-width: 100%;
				}

				@media ${device.ipadLandscape} {
					width: 100%;
					margin: 0;
				}
			}

			&.o-column--full {

				&.counselor-section {
					br {
						display: block !important;
					}
				}
			}

			h2 {
				line-height: 5rem;
				padding: 0;
				width: 100%;
				margin: auto;
			}

			h2 {
				margin-top: 50px;
			}

			.o-header--h2 {
				margin-top: 0;
			}

			.o-inner {
				padding-left: 100px;
				padding-right: 100px;
			}

			@media ${device.laptopMedium} {
				padding: 27px 0 24px;
				.o-inner {
					padding-left: 0px;
					padding-right: 0px;
				}

				h2 {
					line-height: 3rem;
					font-size: 2.5rem;
					padding: 0 30px;
					margin: auto;
					margin-top: 50px;
				}
			}
			.o-internal--h2 {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: #000000;
				text-align: center;

				@media ${device.laptopMedium} {
					font-size: 2.5rem;
					line-height: 3rem;
					max-width: 320px;
				}
			}

			.o-column--full {
				width: 100%;
			}
		}

		.accordion-section {
			padding-top: 84px;
			padding-bottom: 0;

			.panel-default > .panel-heading + .panel-collapse > .panel-body {
				border-top-color: #eeeeee;
				background-color: #f7f7f7;
				padding-left: 27px;
				padding-bottom: 20px;
				padding-right: 30px;

				@media ${device.ipadLandscape} {
					padding-left: 0;
				}
			}

			@media ${device.laptopMedium} {
				padding-top: 48px;
				padding-bottom: 0px;
			  }

			.o-container {
				@media ${device.laptopMedium} {
					padding-left: 0;
					padding-right: 0;
				}
			}

			h3 {
				text-align: center;
				color: #000000;
				max-width: 700px;
				margin: auto;
				padding-bottom: 40px;

				@media ${device.laptopMedium} {
				  font-size: 2.5rem;
				  line-height: 3rem;
				  font-weight: 600;
				}
			}

			.o-header--h2 {
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				color: #000000;
				text-align: center;

				@media ${device.laptopMedium} {
				  font-size: 2.5rem;
				  line-height: 3rem;
				}
			}

			p {
				text-align: center;
				color: rgb(0, 0, 0);
				max-width: 820px;
				margin: auto;
				font-weight: 400;
			}

			p.inherited-disclaimer {
				font-size: 2.2rem;
				line-height: 3.2rem;
				max-width: 90%;
				font-family: 'Gotham-Book';
				margin-bottom: 64px;
			}

			.accordion-link-text {
				text-align: right;
				max-width: 100%;
				margin: auto;

				@media ${device.laptopMedium} {
				  text-align: center;
				  padding: 15px 20px;
				}

				a {
				  color: #000000;
				  font-weight: 700;
				  padding-right: 32px;
				  font-size: 2.2rem;
				  line-height: 3.2rem;

				  	&:last-child {
						padding-right: 0px;
						text-decoration: none;
					}

					&:hover {
					  color: #8000BE;
					  text-decoration: underline !important;
					  text-underline-offset: 3px;
					  transition: all 0.3s ease;
					}

					@media ${device.tabletMax} {
						font-size: 1.8rem;
						line-height: 2.6rem;
					}
				  }

				  .active {
					color: #8000BE;
					text-decoration: underline;
					text-underline-offset: 3px;
					transition: all 0.3s ease;
				  }

				  @media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
				  }
				}
				a.active {
				  text-decoration: underline;
				  text-underline-offset: 3px;
				  transition: all 0.3s ease 0s;
				  color: #8000BE;
				}
			  }

			  .panel-group {

				.panel-default > .panel-heading {
					padding: 0;
					border-radius: 0;
					color: #212121;
					background-color: #fafafa;
					border-color: #eeeeee;
				}

				.collapse:not(.show) {
					display: none;
				}

				.panel {
					border-radius: 0;
					margin: 20px 0;
					box-shadow: none;
					border: 1px solid #8000BE;
					box-shadow: 0 4px 6px #cecece;


				  .panel-heading {
					.panel-title {
					  display: flex;
					  align-items: center;
					  position: relative;
					  padding: 30px 32px 30px 27px;
					  margin: 0px;

					  @media ${device.laptopMedium} {
						padding-left: 16px;
					  }

					  .accordion-icon {
						width: 37px;
						height: 37px;
						margin-right: 27px;
						font-size: 42px;
						margin-top: 4px;
						border-color: transparent;
						background: transparent;
						justify-content: center;
						display: flex;
						align-items: center;
						color: rgb(128, 0, 190);
						display: none;

						@media ${device.laptopMedium} {
						  width: 25px;
						  margin-right: 16px;
						  font-size: 35px;
						}

						&:before {
						  content: " ";
						  height: 42px;
						  width: 42px;
						  margin-left: -12px;

						  @media ${device.laptopMedium} {
							margin-left: -22px;
						  }
						}
					  }

						.scientific {
							color: rgb(128, 0, 190);
							line-height: 3.4rem;
							font-size: 22px;
							text-decoration: none;

							@media ${device.laptopMedium} {
								width: 80%;
								font-size: 20px;
								line-height: 2.6rem;
							}
					  }
					  .o-text-title {
						width: 100%;

						@media ${device.tabletMax} {
						  width: 80%;
						}
					  }

					  .glyphicon {
						font-family: dtp;
						line-height: 40px;
						font-style: normal;
						box-shadow: rgb(0 0 0 / 40%) 0px 4px 6px;
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
						color: rgb(255, 255, 255);
						background-color: rgb(128, 0, 190);
						border-radius: 50%;
						width: 40px;
						height: 40px;
						text-align: center;
						line-height: 40px;
						font-size: 1.6rem;
						color: transparent;
					  }
					  .glyphicon-plus {
						&:before {
						  content: ' ';
						  height: 20px;
						  width: 20px;
						  display: block;
						  margin-top: 10px;
						  margin-left: 10px;
						}
					  }
					  .glyphicon-minus {
						&:before {
						  content: ' ';
						  height: 20px;
						  width: 20px;
						  display: block;
						  margin-top: 10px;
						  margin-left: 10px;
						}
					  }
					}
				  }
				  .panel-body {
						.o-paragraph {
							color: rgb(48, 0, 100);
							text-align: left;
							margin: auto;
							font-weight: 400;
							font-size: 2.2rem;
							line-height: 3.2rem;

							@media ${device.laptopMedium} {
							padding-top: 20px;
							font-size: 1.8rem !important;
							line-height: 2.6rem !important;
							padding-left: 30px;
							}

							@media ${device.ipadLandscape} {
								padding-left: 16px;
							}
						}
						.listing-section {
							margin: 0px;
							width: 100%;
							text-align: left;
							display: table;
							list-style: none;
							padding-left: 0;
							@media ${device.ipadLandscape} {
								padding-left: 16px;
							}

							li {
							display: block;
							padding: 8px 0px 8px 25px;
							color: rgb(0, 0, 0);
							font-family: 'Gotham-Book';
							text-align: left;
							font-size: 2.2rem;
							font-weight: 500;
							line-height: 3.2rem;
							position: relative;

							&:before {
								top: 19px;
								content: "";
								position: absolute;
								background-color: rgb(128, 0, 190);
								width: 8px;
								height: 8px;
								margin: auto;
								left: 0px;
							}
					  }
					}

					.listing-head {
						.o-columns--three {
							margin-bottom: 10px;
							.o-paragraph {
								margin-left: 0;
							}
						}
					}

					.list-para-accord {
						p {
							text-align: left;
							font-size: 1.8rem;
							max-width: 100%;
						}
						a {
							color: #8000BE;
						}
						@media ${device.ipadLandscape} {
							padding-left: 16px;
						}
					}

					.listing-second {
						padding-bottom: 10px;

						.o-columns--three {
							.o-paragraph {
								margin-left: 0;
							}
						}
					}

					.listing-main {
					  display: flex;
					  padding-bottom: 10px;

					  @media ${device.laptopMedium} {
						display: block;
						padding-left: 0;
					  }

					  .o-columns--three {
							width: 100%;
							@media ${device.laptopMedium} {
								width: 100%;
							}
					  }
					}
				}
			}
		}
	}

		.step-to-genetic-testing-more-answers {

			@media ${device.laptopMedium} {
				margin: auto;
			}
			h2 {
				@media ${device.laptopMedium} {
					max-width: 100%;
					margin: auto;
				}
			}
			.o-paragraph {
				text-align: center;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
    				line-height: 2.2rem;
				}
			}

			.o-inner {
				padding-left: 100px;
				padding-right: 100px;

				@media ${device.laptopMedium} {
					padding-left: 0px;
					padding-right: 0px;
				}
			}

			.image-text-card {
				padding-top: 110px;
				padding-bottom: 78px;
				margin-left: auto;
				margin-right: auto;

				@media ${device.ipadLandscapemin} {
					padding-left: 100px;
					padding-right: 100px;
				}

				h2 {
					color: #000000;
				}

				.box-bottom-paragraph {
					@media ${device.laptopMedium} {
						padding: 20px 0 0 0;
					 }
				}

				p {
					max-width: 768px;
    				padding: 40px 0 42px 0;
					color: #000000;
    				text-align: center;
					margin: auto;

					@media ${device.laptopMedium} {
						padding: 12px 0 5px 0;
					}

				}

				@media ${device.laptopMedium} {
					padding-top: 44px;
    			padding-bottom: 44px;
				}
			}

			.o-internal--h2 {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: #000000;
				text-align: center;
				font-family: Gotham, sans-serif;

				@media ${device.laptopMedium} {
					font-size: 2.5rem;
					line-height: 3rem;
				}
			}

			.box-card {
				display: flex;
				align-items: center;
				justify-content: center;
				max-width: 768px;
				margin: auto;

				@media ${device.laptopMedium} {
					display: block;
				 }

				.box-inner-card {
					background-color: #300064;
					width: 40%;
					padding: 32px 32px 28px 32px;
					margin: auto 16px;
					flex: 1;
					min-height: 233px;

					@media ${device.laptopMedium} {
						padding: 24px 42px 20px 42px;
						min-height: auto;
    					height: 100%;
						width: 100%;
						margin: 0;
						margin-top: 15px;
					}

					&:first-child {
						margin-left: 0;
					}

					&:nth-child(2) {
						margin-right: 0;
					}

					&:nth-child(2) {
						img {
							width: 68px;
							@media ${device.laptopMedium} {
								width: 40px;
							}
						}
					}

					p {
						padding: 10px 0 0;
						color: #fff;
					}

					img {
						margin: auto;
						text-align: center;
						display: block;
						max-width: 100%;
						vertical-align: middle;

						@media ${device.laptopMedium} {
							width: 28px;
						}
					}

				}
			}
		}
`;

export const StepsToGenetic = styled.div`

	.o-container {
		@media ${device.laptopMedium} {
			padding: 0 2.7rem !important;
		}
	}

	.tab-content .generic-slider-no-image {
		@media ${device.laptopMedium} {
			padding-top: 0;
		}
	}

  	.carouselHead {
		padding-top: 41px;
		padding-bottom: 41px;

		@media ${device.laptopMedium} {
			padding: 32px 0 0 0;
		}

		p {
			color: #000000;
			font-weight: 700;
			text-align: center;
			font-size: 4.2rem;
			line-height: 5rem;

				@media ${device.laptopMedium} {
					padding: 2px 20px 20px 20px;
					font-size: 2.5rem;
					line-height: 3rem;
					margin-bottom: 0;
				}
		}
  	}

	.o-top-space {
		@media ${device.laptopMedium} {
			padding: 0 !important;
			max-width: unset;
		}
	}

	.setptogenetic-tab {

		.card {

			.tabbable-responsive  {
				 > .o-container {
					max-width: unset !important;
					padding: 0 !important;
				}
				.tabbable {
					.nav-tabs {
						.nav-item {
							.nav-link {
								&:focus-visible {
									box-shadow: inset 0 0 0 2px #fff !important;
    							border: solid 1px blue !important;
								}
							}
							button {
								&:focus {
									outline: 5px auto -webkit-focus-ring-color;
								}
							}
						}
					}
				}
			}

			.carouselSlides {
				.o-container {

					@media ${device.laptopMedium} {
						padding: 0 15px !important;
					}
				}

				.back-btn {
					&::before {
						transform: rotate(180deg);
						height: 17px;
						margin-right: 10px;

						@media ${device.laptopMedium} {
							margin-left: 0;
							height: 16px;
							margin-right: 0;
						}
					}
					&:hover {
						&::after {
							@media ${device.laptopMedium} {
								margin-right: 0;
							}
						}
					}
				}

				.forward-btn {
					&::after {
						transform: rotate(360deg);
						height: 17px;
						margin-left: 10px;
						position: relative;
						top: 2px;
					}
				}
			}

			.tabbable-responsive .tabbable {
				overflow: hidden;

				#tabbed-content-tabpane-tab1 {
					.footer-block--row {
						.media--image {
							img {
								@media ${device.laptopMedium} {
									max-width: 33px;
								}
							}
						}
					}
				}

				.tab-content {
					.generic-slider-no-image {
						@media ${device.laptopMedium} {
							margin-top: -40px;
						}
					}
					.step-title {
						margin-bottom: 0;

						@media ${device.laptopMedium} {
							padding: 2px 50px 30px 50px;
						}
					}

					.btn-wrapper-slider {
						margin-top: 53px;

						@media ${device.laptopMedium} {
							margin-top: 33px;
						}
					}

					.slick-list {
						min-height: 411px;

						@media ${device.laptopPros} {
							min-height: 443px;
						}

						@media ${device.tablet} and ${device.laptopMedium} {
							min-height: 311px;
						}
					}

					.slick-slider  .item__content {
						margin: 196px auto auto;

						@media ${device.laptopMedium} {
							margin-top: 50px;
						}

						img {
							width: 64px;
							margin: auto;
							@media ${device.laptopMedium} {
								width: 41px;
							}
						}

						.wizard-paragraph {
							margin-bottom: 0;
							font-size: 2.2rem;
							font-weight: 500;
							line-height: 3.2rem;

							@media ${device.laptopMedium} {
								font-size: 1.8rem;
								line-height: 2.2rem !important;
							}
						}
					}
				}

				.nav-tabs {
					max-width: 1208px;
					margin-left: auto;
					margin-right: auto;
					padding: 0 2rem;
					white-space: nowrap;
					display: flex;
					justify-content: center;
					align-items: center;
					border: none;
					margin-bottom: 0 !important;
					min-width: unset;

					@media ${device.laptopMedium} {
						max-width: 718px;
						margin-bottom: 15px !important;
						padding: 0 27px;
					}

					.nav-item {
						background-color: unset;
						p {
							margin-bottom: 0;
						}
						button {
							border-color: aliceblue;
						}

						.nav-link {
							font-size: 2.2rem;
							font-weight: 500;
							line-height: 3.2rem;

							@media ${device.laptopMedium} {
								font-size: 1.8rem;
								line-height: 2.2rem;
							}

							&:hover {
								font-weight: 700;
								outline: none;
								border: none;
							}
						}
					}

				}

				br {
					display: none;

					@media ${device.laptopMedium} {
						display: block;
					}
				}

				.counselor-section {
					@media ${device.laptopMedium} {
						padding: 48px 0px 24px;
					}

					.gatsby-image-wrapper {
						width: 100%;
					}
				}
			}
		}

		#tabbed-content-tabpane-tab1 {

			.disclaimer-para {
				display:none !important;
			}

			.listing-btm-section {
				.o-paragraph {
					font-size: 2.7rem;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.4rem;
					}
					 span {
						font-size: 2.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
							line-height: 2.2rem;
						}
					 }
				}
			}
			.common-paragraph {
				p {
					font-size: 2.7rem;
					line-height: 3.4rem;

					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.4rem;
					}
				}
				&.o-top-space {
					@media ${device.laptopMedium} {
						max-width: unset;
					}
				}
			}

			.result-main-section {
				ul {
					margin-bottom: 0;
				}

				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.6rem;
					}
				}

				.communicate-btn  {
					a {
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 2.4rem;
						background: #8000BE;
						color: #fff;
						border: 1px solid #fff;

						&:hover {
							background: #fff;
							color: #8000BE;
						}

            @media ${device.laptopMedium} {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
      }

			.step-to-genetic-testing-more-answers {
				max-width: 1208px;
				margin-left: auto;
				margin-right: auto;
				padding: 0 2rem;

				@media ${device.laptopMedium} {
					padding: 0 2.7rem;
					max-width: 718px;
				}
			}

			.o-paragraph {
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

			}

			.identify-section {
				.line-graph-copy {
					margin-bottom: 0;
				}

				.mobile-content {
					@media ${device.laptopPros} {
						padding: 40px 17px 30px;
					}
				}

				.mobile-img {
					img {
						@media ${device.tabletMax} {
							width: 81%;
						}
					}
				}

			}

			.footer-inner-block {
				@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
					margin-bottom: 0;
				}

				h3 {
					font-weight: 700;

					@media ${device.laptopMedium} {
						min-height: unset;
						font-weight: 600;
						font-size: 20px;
						line-height: 24px;
					}
					@media ${device.desktopsignup} {
						padding: 0 2rem;
					}
				}

				&:last-child {
					@media ${device.laptopMedium} {
						margin-bottom: 10px;
					}
          .media--image {

						@media ${device.ipadLandscape} {
							padding: 15px 0 16px;
						}
            img {
              padding: 20px 0;
              @media ${device.ipadLandscape} {
                padding:0 0;
                margin: 0 auto;
              }
            }
          }
				}
			}
		}
	}

	.wizard-paragraph-spacing {
		padding-top: 20px !important;
		font-weight: 400 !important;
		display: block;

	}

	.result-main-section {
		padding-top: 37px;
		padding-bottom: 120px;

		@media ${device.laptopMedium} {
			padding-top: 20px;
			padding-bottom: 48px;
		}

		.listing-section {
			display: table;
			@media ${device.laptopMedium} {
				margin-bottom: 0;
			}
    		padding: 0;

			li {
				position: relative;
				list-style-type: none;
				padding: 4px 0 0px 25px;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					padding: 12px 0 0px 24px;
				}
				&:before {
					content: "";
					position: absolute;
					background-color: #8000BE;
					width: 8px;
					height: 8px;
					top: 16px;
					transform: translateY(-50%);
					margin: auto;
					left: 0;

					@media ${device.laptopMedium} {
						top: 24px;
					}
				}
			}
		}
		.form-card {
			position: relative;
			padding-left: 98px;
			padding-bottom: 42px;

			@media ${device.laptopMedium} {
				padding-left: 0px;
				padding-right: 0px;
				padding-bottom: 24px
			}

			img {
				position: absolute;
				left: 0;
				top: 0;

				@media ${device.laptopMedium} {
					margin: auto;
					display: block;
					position: revert;
					height: 40px;
				}
			}
		}
		.result-title {
			color: #8000BE;
			font-size: 3.4rem !important;
			line-height: 4.7rem !important;

			@media ${device.laptopMedium} {
				text-align: center;
				font-size: 2.2rem !important;
				line-height: 2.6rem !important;
				margin-top: 12px;
			}
		}

		h3 {
			margin-bottom: 0;

			&.o-title-text {
				padding-top: 0;
			}
		}

		h4 {
			font-size: 2.7rem;
			line-height: 3.8rem;
			padding: 0 0 10px 0;
			margin-bottom: 0;

			@media ${device.laptopMedium} {
				text-align: center;
				font-size: 2rem !important;
				line-height: 2.4rem !important;
				padding: 0 !important;
			}
		}
		.communicate-btn {
			margin: auto;
			display: flex;
			justify-content: center;

			a {
				margin: auto;
				box-shadow: 0px 3px 4px #d6d6d6;

				@media ${device.laptopMedium} {
					text-align: center;
					line-height: 2.2rem;
					padding: 16px 24px;
					margin: 0 20px;
					font-size: 1.8rem;
				}
				&:after {
					content: "";
					display: inline-block;
					position: relative;
					height: 14px;
					top: 0;
					width: 16px;
					left: 7px;
					top: 2px !important
				}
			}
		}
		.o-button--primary {
			background: #8000BE;
			color: #fff;
			border: 1px solid #fff;
		}

		.o-button {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.4rem;
			text-decoration: none;
			z-index: 1;
			position: relative;
			display: inline-block;
			vertical-align: top;
			overflow: hidden;
			padding: 16px 22px;
			border: none;
			letter-spacing: 0;
			border-radius: 6px;
			cursor: pointer;

			&:hover {
				background:#FFF;
				transition: ease 0.3ms;
				color: #8000BE;
				border-color: #fff;
				font-weight: 700;
			}
		}
	}

	.step-info-box {

		@media ${device.laptopMedium} {
			text-align: center;
		}
		.locate-btn {
			display: flex;
			align-items: center;
			justify-content: center;

			a {
				@media ${device.laptopMedium} {
					padding: 0 !important;
				}

				&:last-child {
					display: none;
				}

				&.o-paragraph {
					&:hover {
						font-weight: 500;
						font-size: 2.25rem;
					}
				}

			}

      .media--image {
        @media ${device.laptopMedium} {
          padding-left: 8px;
          padding-top: 8px;
        }
        img {
          @media ${device.laptopPros} {
            width: 17px;
          }
          width: 18px;
        }
      }
    }
    .find-heading-style {
      span {
        font-size: 2.7rem;
        color: #00ffd9;
      }
    }
  }


	.affordable-section {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding-bottom: 1.9rem;

		img {
			display: none;
		}
	}

	.desktop-img {
		@media ${device.laptopPros} {
		  display: none;
		}
	  }

	.mobile-img {
		@media ${device.tablet} {
		  display: none;
		}
	}

	.identify-section {
		margin-bottom: 120px;
		background-color: #000000;

		@media ${device.laptopMedium} {
			margin-bottom: 28px;

		}

		.desktop-img {
			.media--image {
				.gatsby-image-wrapper {
					img {
						left: 30px;
					}
				}
			}
		}

		.mobile-content {
			display: none;
			display: block;
			font-size: 3.4rem;
			line-height: 3.8rem;
			font-weight: 700;
			color: #00FFD9;
			text-align: center;
			padding: 88px 50px 57px;

			@media ${device.laptopPros} {
				font-size: 2.5rem;
				line-height: 2.7rem;
				padding: 40px 16px 30px;
			}

			.over-heading-style {
				font-size: 3.4rem;
				line-height: 3.8rem;
				font-weight: 700;

				@media ${device.laptopPros} {
					font-size: 2.5rem;
					line-height: 2.7rem;
				}
			}
		}

		.mobile-img {
			img {
				@media ${device.laptopPros} {
					margin: auto;
    			display: block;
					width: 81%;
				}
			}
		}

		.line-graph-copy {
			font-size: 18px;
			line-height: 22px;
			text-align: center;
			color: #fff;
			padding: 0 16px 80px;
			margin-top: 5px;

			@media ${device.laptopPros} {
				line-height: 2rem;
    		font-size: 16px;
			}

			a {
				color: #00FFD9;
				text-decoration: none;
			}
		}
	}

	.common-paragraph {

		.o-container {
			@media ${device.laptopMedium} {
				padding: 0 2.7rem !important;
			}
		}

		&.o-top-space {
			padding-top: 38px;
			@media ${device.laptopMedium} {
				padding-top: 0;
			}
		}

		h2 {
			@media ${device.laptopMedium} {
				max-width: 236px;
				margin: auto;
				padding: 0 !important;
			}
		}

		p {
			max-width: initial;
			text-align: center;
			margin: auto;

			@media ${device.laptopMedium} {
				font-size: 2rem;
				line-height: 2.4rem;
				padding: 20px 10px 0;
			}
		}

		.o-title-text {
			padding-top: 22px;
		}
	}

  .carouselSlides {
	@media ${device.laptopMedium} {
		padding: 0 2.7rem;
		max-width: 718px;
		margin-left: auto;
		margin-right: auto;
	}
  @media ${device.ipadLandscapemin} and ${device.smalldeskmid} {
    width: 95%;
    margin: 0 auto;
  }
		.o-container {
			@media ${device.laptopMedium} {
				padding: 0 4.2rem !important;
			}
		}

	.btn-wrapper-slider {
		position: relative;
		display: flex;
		justify-content: center;
		margin-top: 20px;

		.button {
			font-size: 2.2rem;
				font-weight: 500;
				line-height: 2.6rem;
				color: #fff;
				box-shadow: 0px 3px 4px #d6d6d6;
				margin: 5px 10px;
				border: 1px solid #fff;

				&:after {
					margin-left: 15px;
				}

				&:hover {
					transition: ease-in 0.5s;
					font-weight: 700;
					&:after {
					  margin-left: 19px;
					}
				}
			}
		}
    .generic-slider-no-image {
      position: relative;
      padding-top: 0;

      .swiper-pagination {
        bottom: unset;
        background-color: #00FFD9;
        height: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 968px;
        margin: auto;
        text-align: center;
        left: 0;
        right: 0;
        position: absolute;
        transition: 0.3s opacity;
        z-index: 10;

        @media ${device.laptopMedium} {
          width: 100%;
          top: 20px;
        }
        .swipper-texts1::after {

          @media ${device.ipadLandscape} {
            content: "";
          }
        }

      .swipper-texts2::after {
        @media ${device.ipadLandscape} {
          content: "";
        }
      }

		.swipper-texts3::after {
			@media ${device.ipadLandscape} {
				content: "";
			}
		}

		.swipper-texts4::after {
			@media ${device.ipadLandscape} {
				content: "";
			}
		}

		.swipper-texts5::after {
			@media ${device.ipadLandscape} {
				content: "";
			}
		}

		.swiper-progress-bar-fill {
			position: absolute;
			width: 0;
			height: 8px;
			z-index: -1;
			background-color: #7323CA;
		}

        .swiper-pagination-bullet {
          width: 68px;
          height: 68px;
          margin: 0px;
          opacity: 1;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #00FFD9;
          cursor: pointer;
          border-radius: 100%;
          font-weight: 700;
          line-height: 4.7rem;
          font-size: 3.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #7323CA;

					@media ${device.laptopMedium} {
						width: 44px;
          	height: 44px;
						font-size: 2.7rem;
					}
          &:nth-child(4) {
            &:after {
              @media ${device.ipadLandscapemin} and ${device.smalldeskmid} {
                width: 190px;
              }
            }
          }
          &.active {
            background-color: #7323CA !important;
            color: #fff;

						&:before {
							color: #fff;
						}
          }

          &:before {
            font-weight: 700;
            line-height: 4.7rem;
            font-size: 3.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
						color: #7323CA;

						@media ${device.laptopMedium} {
							font-size: 2.7rem;
						}
          }

          &:after {
            line-height: 3.2rem;
            font-size: 2.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #8000BE;
            position: absolute;
            width: 270px;
            top: 80px;
						font-weight: 500;

						@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd}{
							width: 200px;
							font-weight: 500;
						}
          }
        }
				.swiper-progress-bar-fill {
					position: absolute;
					width: 0;
					height: 8px;
					z-index: -1;
					background-color: #7323CA;
				}
      }
    }
		.button {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.6rem;
			color: #fff;
			box-shadow: 0px 3px 4px #d6d6d6;
			margin: 5px 10px;
			border: 1px solid #fff;
			width: 137px;
			height: 54px;
			background-color: #7323CA;
			position: relative;
			display: table-cell;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			cursor: pointer;
			margin: 0 10px;
			padding: 0 0;

			@media ${device.laptopMedium} {
				width: 137px;
			}

			&.back-btn {
				&:hover {
					transition: ease-in 0.5s;
					font-weight: 700;
					&:before {
						margin-right: 19px;
					}
				}
				&:before {
					content: "";
					display: inline-block;
					height: 15px;
					width: 21px;
					margin-right: 15px;
					transform: rotate(90deg);
				}
			}

			&.forward-btn {
				&:after {
					content: "";
					display: inline-block;
					height: 15px;
					width: 21px;
					margin-left: 15px;
					transform: rotate(270deg);
				}
			}
		}
  }
	.slick-slider {

		max-width: 950px;
		margin: 0 auto;

		@media ${device.laptopMedium} {
			margin-top: 0;
			padding: 0 15px;
		}

		.item {
			max-width: 880px;
			margin: auto;

			.item__content {
				display: flex;
				margin: auto;
				margin-top: 185px;

				@media ${device.laptopMedium} {
					margin-top: 50px;
					display: block;
    				width: 100%;
				}

				.item__icon {
					margin-right: 28px;
					min-width: 80px;
					@media ${device.laptopMedium} {
						margin: auto !important;
					}

					.media {
						img {
							@media ${device.laptopMedium} {
								width: 41px;
								margin: 0 auto;
							}
						}
					}
				}

				.form-card-heading {
					font-size: 2.2rem;
					color: #8000BE;
					margin-bottom: 30px;
					display: none;
					font-weight: bold;

					@media ${device.laptopMedium} {
						display: table;
						text-align: center;
						margin: 0 auto 20px;
						font-weight: 500;
						font-size: 1.8rem;
					}

					.item__icon {
						img {
							width: 64px;
							height: auto;
							margin: auto;
    					display: block;

							@media ${device.laptopMedium} {
								width: 41px;
							}
						}
					}
				}
				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
				}

				.wizard-paragraph {

					span {
						font-weight: bold;
					}

					@media ${device.laptopMedium} {
						text-align: center;
    					font-size: 1.8rem;
						line-height: 2.2rem !important;
    					padding-top: 12px;
						max-width: 634px;
						margin: auto;
					}
				}
			}
		}
	}
`;

export const FooterCard = styled.div`
  	.steptogenetic-footer--block {
		padding: 0 88px;
		display: flex;

		@media ${device.laptopMedium} {
			display: block;
			padding: 0;
			padding: 0 42px;
		}

    &.footer-block {
      margin-top: -55rem;

      @media ${device.ipadLandscapemin} {
        margin-top: -485px;
      }

			@media ${device.laptopMedium} {
				background: #8000BE;
			}

			.footer-inner-block-o-gradient {
				&:before {
					background: #00FFD9 !important;
				}
			}
		}
	}

	.footer-block {
		padding: 0 88px;
		display: flex;

		@media ${device.laptopMedium} {
			padding: 0 42px !important;
		}
	}

    .footer-block--row {
      display: flex;

		@media ${device.laptopMedium} {
			display: block;
		}

    .footer-inner-block {
			text-align: center;
			position: relative;
			border-radius: 5px;
			background-color: #fff;
			box-shadow: 0px 2px 4px rgb(17 17 17 / 16%);
			padding: 32px 24px 120px;
			margin: 0 16px;
			width: calc(50% - 15px);
			z-index: 992;

			@media ${device.laptopMedium} {
        padding: 16px 24px 120px;
			}

			@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
				margin-bottom: 58px;
			}

			.o-common-para {
				padding: 10px 5px 20px 5px;
				font-size: 2.2rem;
				line-height: 3.2rem;
				color: #000000;
				text-align: center;
				margin-bottom: 0;

				@media ${device.laptopMedium} {
				font-size: 1.8rem;
				line-height: 2.2rem;
				padding: 10px 0 20px;
				}
			}

			h3 {
				@media ${device.laptopMedium} {
					min-height: unset;
					font-weight: 600;
					font-size: 20px;
					line-height: 24px;
					margin-bottom: 0;
				}
			}

			.o-button {
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				text-decoration: none;
				z-index: 1;
				position: relative;
				display: inline-block;
				vertical-align: top;
				overflow: hidden;
				padding: 10px 25px;
				border: none;
				letter-spacing: 0;
				border-radius: 6px;
				background: transparent;
				transition: all 0.3s ease;
				cursor: pointer;
				margin: auto;
				text-align: center;
				display: block;
				font-family: 'Gotham';

				&:after {

					@media ${device.laptopMedium} {
						display: block;
						margin: auto;
					}
				}
			}

			.o-button:hover{
				background:#FFF;
				transition: ease 0.3ms;
				color: #8000BE;
				border-color: #fff;
				font-weight: 700;
			}

			.o-button--primary {
				background: #8000BE;
				color: #fff;
				border: 1px solid #fff;
				box-shadow: 0px 3px 4px #d6d6d6;
			}

			.media--image {
				img {
					margin: auto;
					text-align: center;
					display: block;
					padding: 20px 0;
					height: 100px;
					width: auto;
					@media ${device.ipadLandscape} {
						width: 100%;
					}
				}
			}

			@media ${device.laptopMedium} {
				width: 100%;
				padding-left: 16px;
				padding-right: 16px;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			&:first-child {
				margin-left: 0;
				.media--image {
					img {
						padding: 8px 0;
						width: 12%;

						@media ${device.ipadLandscape} {
							width: 100%;
							max-width: 33px;
              padding: 16px 0;
              height: auto;
						}
					}
				}
				&:before {
					background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
				}
        	}
        &:last-child {
        .media--image {
          img {
            padding: 20px 0;
            width: auto;
            @media ${device.ipadLandscape} {
              margin: 16px 0;
              padding: 0;
              height: 60px;
            }
          }
        }
					.o-common-title {
						&:before {
							width: 90px;
						}
					}
					@media ${device.laptopMedium} {
						margin-left: 0;
					}
					&:before {
						background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
					}
        }
        &:before {
          content: "";
          width: 100%;
          height: 16px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 5px 5px 0 0;
        }

      .o-common-title {
				font-weight: 700;
				font-size: 2.7rem;
    		line-height: 3.8rem;
				color: #8000BE;
				text-align: center;
				margin-bottom: 0px;


				@media ${device.laptopMedium} {
					font-weight: 600;
					font-size: 20px;
					line-height: 24px;
					min-height: unset;
				}
      }

		p {
			margin-bottom: 0px !important;
		}
        .text-para-wrap {
          padding: 10px 5px 30px 5px;
          font-size: 2.2rem;
          line-height: 3.2rem;
          color: #000000;
          text-align: center;
        }
        .text-button-wrap {
          a {
            box-shadow: 0px 3px 4px #d6d6d6;
            position: absolute;
            left: 25px;
            right: 25px;
            width: 90%;
            bottom: 45px;
            text-align: center;
            background: #8000BE;
            color: #fff;
            border: 1px solid #fff;
            cursor: pointer;
            letter-spacing: 0;
            border-radius: 6px;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            padding: 16px 22px;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 2.4rem;
            text-decoration: none;
            z-index: 1;

				@media ${device.laptopMedium} {
					position: revert;
					margin-left: 0;
					margin-top: 15px;
					width: 100%;
					font-size: 1.8rem;
					line-height: 2.6rem;
				}

				@media ${device.ipadLandscape} {
					padding-left: 15px;
					padding-right: 15px;
					padding-top: 10px;
					padding-bottom: 10px;

				}

            &:hover {
              background: #fff;
              color: #8000BE;
              border-color: #fff;
              font-weight: 700;
              position: absolute;
              left: 25px;
              right: 25px;
              width: 90%;
              bottom: 45px;
              text-align: center;
              border: 1px solid #fff;
            }

						&:after {
							content: "";
							display: inline-block;
							width: 17px;
							height: 14px;
							@media ${device.laptopMedium} {
								display: block;
								margin: 8px auto auto;

							}
						}
          }
        }
      }
    }
`;
