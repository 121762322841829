export const patientSTGT: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Global",
  footerMenu: "Footer Navigation Menu Consumer GCSO - Global",
  banner: "Banner - HTML - Steps Genetic Testing - DTC - US Global",
  footer: "Retina Global Footer Consumer - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu Consumer US - Global",
  footerCallout: "Navigational Callout - Steps To Genetic Testing",
  siteLogo: "Site Logo New - Retina Global",
  tabPara: "Steps to genetic testing - Tabbed content - Global (Updated)",
  stepContent: "Step",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/steps-to-genetic-testing",
      "es": "/es/steps-to-genetic-testing"
    }
  },
  backToTopGTM: "Back to top - steps to genetic testing",
  tabAttrs: {
    tabGTMLabels: [{
      slidersGTM: [
        {
          iconGTMs: [
            {
              'aria-label': '1 Schedule an exam',
              'className': 'gtm-accordion'
            },
            {
              'aria-label': '2 Meet with a genetic counselor',
              'className': 'gtm-accordion'
            },
            {
              'aria-label': '3 Genetic testing will be ordered',
              'className': 'gtm-accordion'
            },
            {
              'aria-label': '4 Discuss the result',
              'className': 'gtm-accordion'
            }
          ],
          prevBtnGTMs: [
            {
              'className': '',
              'aria-label': 'Go to step 1'
            },
            {
              'className': '',
              'aria-label': 'Go to step 2'
            },
            {
              'className': '',
              'aria-label': 'Go to step 3'
            },
            {
              'className': '',
              'aria-label': 'Go to step 4'
            }
          ],
          nextBtnGTMs: [
            {
              'className': '',
              'aria-label': 'Go to step 1'
            },
            {
              'className': '',
              'aria-label': 'Go to step 2'
            },
            {
              'className': '',
              'aria-label': 'Go to step 3'
            },
            {
              'className': '',
              'aria-label': 'Go to step 4'
            }
          ]
        }
      ]
    },
    {
      slidersGTM: [{}]
    }
    ]
  },
  footerClassName: "steps-to-genetic-testing-footer"
}
