import React, { useEffect, useState } from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper, StepsToGeneticFooterContainer } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import {
  GeneticTesting,
  StepsToGenetic,
  FooterCard,
} from './styles'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, tabDataBuilders } from '../../../utils'
import TabbedContent from '@retina-packages/retina-theme-bootstrap/packages/components/TabbedComponents'
import { UsHeaderContainer, HomePageFooterContainer } from "../../../css/common/style"
import retinaConfig from '../../../utils/retinaConfigs'
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap'
import { patientSTGT } from './constants'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'

/**
 * Renders steps to genetic testing page
 *
 * @param props react property passed dynamically
 * @returns
 */
const StepToGeneticTemplate = (props: Props) => {

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsSTGT = props.pageContext
  const htmlStaticFilesSTGT = {
    nonSVGImages: pgContextsSTGT.nonSVGImages,
    svgMediaImages: pgContextsSTGT.svgMediaImages,
    allMediaDocument: pgContextsSTGT.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientSTGT.mainMenu, siteFooterMenu: patientSTGT.footerMenu });

  // Reusable properties building
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientSTGT.footer
  const exitPopupTitle = patientSTGT.exitPopup
  const hcpSwitcherTitle = patientSTGT.hcpSwitcher
  const topNavTitle = patientSTGT.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle })

  const siteLogos = deriveSiteLogo({ blocks, title: patientSTGT.siteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: patientSTGT.banner })

  const tabTitle = patientSTGT.tabPara
  const {
    tabArr,
    tabHead
  }:
    {
      tabHead: never[]
      tabArr: any[]
    } = tabDataBuilders({ blocks, tabTitle })

  const tabData = {
    tabData: tabHead,
    tabContent: tabArr
  }

  const [renderToggle, setRenderToggle] = useState(false)

  useEffect(() => {
    window.addEventListener('hashchange', () => {
      // Rerender the components for exit popup in second tab.
      if (window.location.hash === '#tab1') {
        setRenderToggle(!renderToggle)
      }
    })
    if (window.location.hash === '#tab1') {
      // Rerender the components for exit popup in second tab.
      setRenderToggle(!renderToggle)
    }
  }, [])

  return (
    <UsHeaderContainer>
      <HomePageFooterContainer>
        <StepsToGeneticFooterContainer>
          <GeneticTesting className="genetic-testing">
            {props.pageContext !== null && props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )
            }
            <Layout
              title={"siteTitle"}
              location={props.location}
              data={mainMenu}
              mdata={footerMenu}
              footerData={footerText}
              audience={audience}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              staticMediaFiles={htmlStaticFilesSTGT}
              siteLogos={siteLogos}
              backToTopContent={retinaConfig.backToTopContent}
              toggleRender={renderToggle}
              gtmBackToTopLabel={patientSTGT.backToTopGTM}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
              hcpValidate={retinaConfig.hcpValidate}
              preIndexUrl={retinaConfig.preIndexUrl}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={patientSTGT.footerClassName}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              languageConfig={{ ...retinaConfig.langConfig, ...patientSTGT.redirectLangUrl }}
              {... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
            >
              <PageWrapper className={`pagewrapper genetic-testing`}>
                <div id='tab1'></div>
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesSTGT} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>
                <StepsToGenetic>
                  <div>
                    <FooterCard>
                      <TabbedContent
                        stepContent={patientSTGT.stepContent}
                        data={tabData} location={props.location}
                        mediaStaticFiles={htmlStaticFilesSTGT}
                        onTabChange={setRenderToggle}
                        toggleRender={renderToggle}
                        {...patientSTGT.tabAttrs}
                      />
                    </FooterCard>
                  </div>
                </StepsToGenetic>
              </PageWrapper>
            </Layout>
          </GeneticTesting>
        </StepsToGeneticFooterContainer>
      </HomePageFooterContainer>
    </UsHeaderContainer>
  )
}

export default StepToGeneticTemplate

export const pageQuery = graphql`
query($id: Int!) {
  nodePage(drupal_internal__nid: {eq: $id}) {
    relationships {
      ... STGTPageQuery
    }
  }
}
`
